import React from 'react';
import Decimal from 'decimal.js';

import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_PROVIDER_VAT,
  LINE_ITEM_VAT,
  LINE_ITEM_PROVIDER_COMMISSION_VAT,
} from '../../util/types';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = (lineItems, marketplaceCurrency) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal.currency : marketplaceCurrency;
  return new Money(amount, currency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = (lineItem,userRole) => {
  return (
    (userRole == 'customer' ? lineItem.code === LINE_ITEM_PROVIDER_COMMISSION : '') ||
    (userRole == 'customer' ? lineItem.code === LINE_ITEM_PROVIDER_COMMISSION_VAT : '') ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION ||
    lineItem.code === LINE_ITEM_VAT
  );
};

/**
 * Returns non-commission, reversal line items
 */
const nonCommissionReversalLineItems = (lineItems,userRole) => {
  return lineItems.filter(item => !isCommission(item,userRole) && item.reversal);
};

const LineItemRefundMaybe = props => {
  const { lineItems, intl, marketplaceCurrency,userRole } = props;

  // all non-commission, reversal line items
  const refundLineItems = nonCommissionReversalLineItems(lineItems,userRole);
console.log('refundLineItems',refundLineItems);

  const refund = lineItemsTotal(refundLineItems, marketplaceCurrency);

  const formattedRefund = refundLineItems.length > 0 ? formatMoney(intl, refund) : null;

  return formattedRefund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.refund" />
      </span>
      <span className={css.itemValue}>{formattedRefund}</span>
    </div>
  ) : null;
};

LineItemRefundMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemRefundMaybe;
