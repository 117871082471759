import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldChipbox.module.css';

import Bulldozer from './FieldChipboxImages/Bulldozer.png';
import Compaction from './FieldChipboxImages/Compaction.png';
import Crane from './FieldChipboxImages/Crane.png';
import Dumper from './FieldChipboxImages/Dumper.png';
import Excarvator from './FieldChipboxImages/Excarvator.webp';
import Forklift from './FieldChipboxImages/Forklift.png';
import Platform from './FieldChipboxImages/Platforms.png';
import Scissor from './FieldChipboxImages/Scissor lift.png';
import Telescopic from './FieldChipboxImages/Telescopic Machine.png';
import Tools from './FieldChipboxImages/Tools.avif';
import WheelLoader from './FieldChipboxImages/Wheel Loader.png';
import NoImage from './FieldChipboxImages/NoImage.jpg';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';


const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <path
            className={checkedClassName || css.checked}
            d="M9.9992985 1.5048549l-.0194517 6.9993137C9.977549 9.3309651 9.3066522 10 8.4798526 10H1.5001008c-.8284271 0-1.5-.6715729-1.5-1.5l-.000121-7c0-.8284271.6715728-1.5 1.5-1.5h.000121l6.9993246.0006862c.8284272.000067 1.4999458.671694 1.499879 1.5001211a1.5002208 1.5002208 0 0 1-.0000059.0040476z"
          />
          <path
            className={boxClassName || css.box}
            strokeWidth="2"
            d="M10.9992947 1.507634l-.0194518 6.9993137C10.9760133 9.8849417 9.8578519 11 8.4798526 11H1.5001008c-1.3807119 0-2.5-1.1192881-2.5-2.4999827L-1.0000202 1.5c0-1.3807119 1.119288-2.5 2.500098-2.5l6.9994284.0006862c1.3807118.0001115 2.4999096 1.11949 2.4997981 2.5002019-.0000018.003373-.0000018.003373-.0000096.0067458z"
          />
        </g>
        <path
          d="M5.636621 10.7824771L3.3573694 8.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254782 2.8256927-4.5462307c.3934117-.5431636 1.1545778-.6695372 1.7055985-.278265.5473554.3912721.6731983 1.150729.2797866 1.6951077l-3.6650524 5.709111c-.2199195.306213-.5803433.5067097-.9920816.5067097-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};
const images =[
  {key:'bulldozer',value:Bulldozer},
  {key:'compaction',value:Compaction},
  {key:'crane',value:Crane},
  {key:'dumper',value:Dumper},
  {key:'excavator',value:Excarvator},
  {key:'forklift',value:Forklift},
  {key:'platform',value:Platform},
  {key:'scissor-lift',value:Scissor},
  {key:'telescopic-machine',value:Telescopic},
  {key:'tools',value:Tools},
  {key:'wheel-loader',value:WheelLoader},
]
IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldChipboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    input,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(location?.search?.includes(rest?.value));
  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    setIsClicked(event?.target?.checked);
    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
        checkedClassName: css.checkedSuccess,
        boxClassName: css.boxSuccess,
      }
    : {};
  const disabledColorMaybe = rest.disabled
    ? {
        checkedClassName: css.checkedDisabled,
        boxClassName: css.boxDisabled,
      }
    : {};

    const [vehicle, setVehicle] = useState("");
    const imageObject = images.find(x=>x.key===vehicle);
  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const { input, disabled } = props;
          setVehicle(input.value);
          return (
            <>
              <input
                id={id}
                className={css.input}
                {...input}
                onChange={event => handleOnChange(input, event)}
                disabled={disabled}
              />
            </>
          );
        }}
      </Field>
      <label htmlFor={id} className={css.chipLabel}>
        <span >
            <div htmlFor={id} className={isClicked ? css.activeCategory : css.category}>
            <div className={css.imageBlock}>
              <img src={imageObject ? imageObject.value : NoImage} width={'100%'}/>
            </div>
            <h4 className={css.titleCategory}><FormattedMessage id={`FieldChipbox.category${label}`}/>
            { isClicked ? 
              <span className={css.clearFilters} >
                &times;
              </span>
            : null }
            </h4>
          </div>
        </span>
      </label>
    </span>
  );
};

FieldChipboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldChipboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldChipboxComponent;
